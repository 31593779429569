.slider-card-container {
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid #e6e6fa;
  border-radius: 8px;
  background-color: #1a1a1a;
  overflow: hidden;
  height: 250px;
  width: 80%;
  margin-bottom: 2rem;
  &:hover {
    .slider-card-back {
      transform: translateY(0);
    }
  }
}
.slider-card-front {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.image-title {
  color: #fff;
}
.slider-card-back {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transition: all 0.4s ease-in;
  transform: translateY(100%);
  backdrop-filter: blur(50px);
  background-color: #e6e6fa;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    width: 85%;
    text-align: center;
  }
  h2 {
    color: #8a2be2;
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .slider-card-container {
    width: 250px;
    height: 200px;
    margin-bottom: 0;
  }
}
