.modal-title {
  color: #8a2be2;
  margin: 1rem 2rem 0 2rem;
  font-size: 1.5rem;
  font-weight: 600;
}
.modal-body {
  padding: 2rem 2rem 0 2rem;
  box-sizing: border-box;
}
.inline-field {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  input {
    width: 100%;
  }
}
.modal-field {
  width: 100%;
}
input,
textarea {
  padding: 0.7rem 0 0.7rem 1rem;
  outline: none;
  border: 1px solid gray;
  border-radius: 5px;
  width: 98%;
  margin-bottom: 1.5rem;
  font-family: "Roboto", sans-serif;
}
select {
  padding: 0.7rem 0 0.7rem 1rem;
  outline: none;
  border: 1px solid gray;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 2rem;
  max-height: max-content;
  option {
    padding: 0.5rem;
  }
}
.modal-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2rem;
}
.modal-btn {
  border: none;
  outline: none;
  color: #fff;
  padding: 0.7rem 2rem;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 0;
}
.cancel-btn {
  background-color: #8f8c8c;
  &:hover {
    background-color: #666565;
  }
}
.submit-btn {
  background-color: #8a2be2;
  &:hover {
    background-color: #5e0fa7;
  }
}

@media screen and (min-width: 768px) {
  .inline-field {
    flex-direction: row;
    input {
      width: 45%;
    }
  }
  .modal-footer {
    justify-content: flex-end;
  }
  .modal-btn {
    margin-left: 1.5rem;
  }
}
