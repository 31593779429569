.why-choose-us-container {
  background-image: url("../../assets/why-choose-us.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 4rem 3rem;

  h1 {
    margin: 0 0 1.5rem 0;
  }
}

.bullet-point {
  display: flex;
  align-items: center;

  .circle {
    width: 12px;
    height: 12px;
    background-color: #8a2be2;
    border-radius: 50%;
    margin-right: 0.7rem;
  }

  p {
    font-size: 1.1rem;
    width: 98%;
  }
}

@media screen and (min-width: 768px) {
  .bullet-point {
    p {
      width: 60%;
    }
  }
}
