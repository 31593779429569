.service-card-container {
  padding: 2rem;
}
.service-card-icon {
  max-height: 100px;
  max-width: 100px;
  color: #1a1a1a;
}
.service-card-title {
  color: #1a1a1a;
}
.service-card-desc {
  color: #999999;
}
